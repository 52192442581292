<template>
  <div>
    <graphTitle class="title" :title="'停车饱和度趋势分析'">
      <el-tooltip
        placement="top"
        content="展示所选日期内各个时间段的停车场饱和度变化情况，用来分析停车高峰与低谷时段、入场与出场压力较大的时段"
      >
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="city" class="city"></div>
    <el-form
      :inline="true"
      label-position="right"
      :model="formInline"
      :style="{ width: clentWidht }"
      class="demo-form-inline"
    >
      <el-form-item
        label=""
        prop="state"
        style="position: relative; left: 79%; top: -306px; width: 150px"
      >
        <el-select v-model.trim="formInline.dateType" filterable size="mini" @change="getTimeNow">
          <el-option label="按时间对比" value="0">按时间对比</el-option>
          <el-option label="按计费类型对比" value="1">按计费类型对比</el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    berTwo: {
      default: function () {
        return {};
      },
    },
    time: {
      default: function () {
        return {};
      },
    },
    NumberTwo: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      clentWidht: "",
      bigtime: "",
      receiver: "",
      type: "c2",
      timeList: [],
      totalNum: 0,
      nameList: [],
      dataList: [],
      formInline: {
        dateType: "0",
      },
      // 日期（共用）
      dateList: [],
      seriesValue2: [],
      seriesValue3: [
        {
          name: "一类区",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#FF3300",
          },
          itemStyle: {
            normal: {
              color: "#FF3300",
            },
          },
        },
        {
          name: "二类区",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#9933CC",
          },
          itemStyle: {
            normal: {
              color: "#9933CC",
            },
          },
        },
        {
          name: "三类区",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#0f6eff",
          },
          itemStyle: {
            normal: {
              color: "#0f6eff",
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle2: "",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "",
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    NumberTwo: {
      handler(value) {
        if (value === 2) {
          this.formInline.dateType = "0";
          this.type = "c2";
        } else if (value === 1) {
          this.formInline.dateType = "1";
          this.type = "c3";
        } else {
          this.formInline.dateType = "0";
          this.type = "c2";
        }
        this.drawGraph();
      },
    },
    // 获取选取的日期以此来获取过去七天日期
    // time: {
    //   handler(value) {
    //     this.bigtime = value;
    //     this.getLastOrNextFewDateBy(this.bigtime)
    //   },
    //   immediate: true
    // },
    // 停车饱和度趋势分析
    berTwo: {
      handler(value) {
        console.log(value, "value");
        this.dateList = [];
        this.timeList = []
        // this.seriesValue3[0].data = [];
        // this.seriesValue3[1].data = [];
        // this.seriesValue3[2].data = [];

        // this.seriesValue2[0].data = [];
        // this.seriesValue2[1].data = [];
        // this.seriesValue2[2].data = [];
        // this.seriesValue2[3].data = [];
        // this.seriesValue2[4].data = [];
        // this.seriesValue2[5].data = [];
        // this.seriesValue2[6].data = [];
        this.seriesValue2 = [];
        for (let i = 0; i < value.length; i++) {
          this.timeList.push(value[i]['dateDay'])
          if (this.formInline.dateType == '0') {
            // Object.values(value[i]).pop()
            this.seriesValue2[i] = { name: '', type: 'line', data: [], lineStyle: { color: this.$colorList[i] }, itemStyle: { normal: { color: this.$colorList[i] } } };
            this.seriesValue2[i].name = value[i]['dateDay']
            this.seriesValue2[i].data = Object.values(value[i]).filter((res, index) => {
              return index % 2 == 0
            }).map(res => { return (res * 100).toFixed(2) });
          } else {
            this.seriesValue3[i].data = Object.values(value[i]).filter((res, index) => {
              return index % 2 == 0
            }).map(res => { return (res * 100).toFixed(2) });
          }
          // for (var x in value[i]) {
          //   if (this.formInline.dateType == '0') {
          //     let dateTime = value[i].dateDay.split('-')
          //     let concatTime = dateTime[1] + '月' + dateTime[2] + '日'
          //     // 判断返回的日期全等于计算的过去七天日期的话 添加数据到相应的数组
          //     if (concatTime == this.timeList[0]) {
          //       this.seriesValue2[0].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[1]) {
          //       this.seriesValue2[1].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[2]) {
          //       this.seriesValue2[2].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[3]) {
          //       this.seriesValue2[3].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[4]) {
          //       this.seriesValue2[4].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[5]) {
          //       this.seriesValue2[5].data.push((value[i][x] * 100).toFixed(2));
          //     } else if (concatTime == this.timeList[6]) {
          //       this.seriesValue2[6].data.push((value[i][x] * 100).toFixed(2));
          //     }
          //   } else {
          //     this.seriesValue3[0].data.push((value[0][x] * 100).toFixed(2));
          //     this.seriesValue3[1].data.push((value[1][x] * 100).toFixed(2));
          //     this.seriesValue3[2].data.push((value[2][x] * 100).toFixed(2));
          //   }
          // }
        }
        // 数据更新后刷新图表
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    getTimeNow() {
      if (this.formInline.dateType == 0) {
        this.type = 'c2';
        this.$emit('getData', 2)
        // this.getLastOrNextFewDateBy(this.bigtime)
      } else {
        this.type = 'c3';
        this.$emit('getData', 1)
        // this.getLastOrNextFewDateBy(this.bigtime)
      }
      this.drawGraph();
    },
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart =
        document.getElementById("city") && this.$echarts.init(document.getElementById("city"));
      let option = {
        title: {
          text: that.type == "c2" ? that.textTitle2 : that.type == "c3" ? that.textTitle3 : "",
        },
        legend: {
          type: 'scroll',
          width: '700',
          data: that.type == "c2" ? that.timeList : that.type == "c3" ? ['一类区', '二类区', '三类区'] : ['历史营收趋势']
        },
        tooltip: {
          trigger: "axis",
          padding: [15, 15, 15, 30],
          formatter: (params) => {
            let tipText = ''
            tipText += params[0].name + '<br/>'
            if (this.formInline.dateType == 0) {
              params.forEach(e => {
                tipText += e.marker + `${e.seriesName}：${e.value}%<br/>`
              });
            } else {
              params.forEach((e) => {
                tipText += e.marker + `${e.seriesName}：${e.value}%<br/>`;
              });
            }
            return tipText;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		      magicType: {show: true, type: ['stack', 'tiled']},
            //		      saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: this.dateList
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:0",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          name: "饱和度",
          min: 0,
          max: 100,
          axisLabel: {
            formatter(value) {
              return value + "%";
            },
          },
        },
        series: that.type == "c2" ? that.seriesValue2 : that.type == "c3" ? that.seriesValue3 : "",
      };
      circularChart.setOption(option, true);
    },
    // 获取过去七天包含今天的日期(不包含年月)
    // get7day() {
    //   var arr = [];
    //   var now = new Date().getTime();
    //   var oneDayTime = 60 * 60 * 24 * 1000;
    //   // 一天的秒数
    //   for (var i = 6; i >= 0; i--) {
    //     var dd = new Date(now - i * oneDayTime).getDate();
    //     arr.push(dd < 10 ? '0' + dd : dd + '');
    //   }
    //   // console.log(arr)
    // },
    // 获取过去七天日期包含今天
    getLastOrNextFewDateBy(value) {
      this.timeList = [];
      let timedate = value !== "" ? value : this.bigtime !== "" ? this.bigtime : "";
      for (let i = -6; i <= 0; i++) {
        let today = new Date();
        if (timedate) {
          this.receiver = timedate;
        } else {
          this.receiver = today;
        }
        let lastOrNextDate = this.receiver.getTime() + 1000 * 60 * 60 * 24 * i;
        today.setTime(lastOrNextDate);
        // var year = today.getFullYear();
        let month = today.getMonth() + 1;
        if (this.receiver == timedate) {
          let date = today.getDate();
          if (date < 10) {
            date = "0" + date;
          }
          if (month < 10) {
            month = "0" + month;
          }
          this.timeList.push(month + "月" + date + "日");
        } else {
          let date = today.getDate() - 1;
          if (date < 10) {
            date = "0" + date;
          }
          if (month < 10) {
            month = "0" + month;
          }
          this.timeList.push(month + "月" + date + "日");
        }
        // this.timeList.push(i != 0 ? month + "月" + date + '日' : '本日')
        // this.timeList.push(month + "月" + date + '日')
      }
      this.timeList.reverse();
      // console.log(this.timeList)
      if (timedate) {
        setTimeout(() => {
          this.getlength();
          this.drawGraph();
        }, 10);
      }
    },
    // getlength() {
    //   this.seriesValue2 = [
    //     { name: this.timeList[0], type: 'line', data: [], lineStyle: { color: '#FF3300' }, itemStyle: { normal: { color: '#FF3300' } } },
    //     { name: this.timeList[1], type: 'line', data: [], lineStyle: { color: '#9933CC' }, itemStyle: { normal: { color: '#9933CC' } } },
    //     { name: this.timeList[2], type: 'line', data: [], lineStyle: { color: '#00FF00' }, itemStyle: { normal: { color: '#00FF00' } } },
    //     { name: this.timeList[3], type: 'line', data: [], lineStyle: { color: '#0f6eff' }, itemStyle: { normal: { color: '#0f6eff' } } },
    //     { name: this.timeList[4], type: 'line', data: [], lineStyle: { color: '#FF9900' }, itemStyle: { normal: { color: '#FF9900' } } },
    //     { name: this.timeList[5], type: 'line', data: [], lineStyle: { color: '#FFFF00' }, itemStyle: { normal: { color: '#FFFF00' } } },
    //     { name: this.timeList[6], type: 'line', data: [], lineStyle: { color: '#171717' }, itemStyle: { normal: { color: '#171717' } } }
    //   ]
    // }
  },
  mounted() {
    this.clentWidht = document.querySelector("#city").clientWidth + "px";
    // this.drawGraph();
  },
  created() {
    // this.getLastOrNextFewDateBy()
    // this.getlength()
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.city {
  width: 100%;
  height: 300px;
  position: relative;
  // overflow: hidden;
}
</style>
